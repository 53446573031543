<template>
  <div class="main-box">
    <div class="sort-score">
      <custom-frame title="新高考选科测评" :height="heightNumber"></custom-frame>
      <div class="main">
        <div class="tips">平时成绩由高到低依次选择学科</div>
        <div class="box">
          <div class="item" :class="selectedItem(item)" @click="sortSubject(item)" v-for="(item, index) in subjectList"
            :key="index">
            <div class="order">{{ sortIndex(item) }}</div>
            <div class="name">{{ item }}</div>
          </div>
        </div>
        <div class="reset-button">
          <span class="button" @click="resetSort">重新选择</span>
        </div>
        <div class="submit-button" :class="submitStatus" @click="submitTest">提交测评</div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'
import CustomFrame from '@/views/test/custom-components/CustomFrame'
// import{SelSubjectTest} from "@/api/test/test"
import { getStore } from "@/utils/utils";
export default {
  components: {
    CustomFrame
  },
  name: "sort-score",
  data() {
    return {
      subjectList: [
        "物理",
        "化学",
        "生物",
        "历史",
        "地理",
        "政治"
      ],
      sortList: [],
      heightNumber: 600
    }
  },
  computed: {
    submitStatus() {
      return this.sortList.length === this.subjectList.length ? 'activate-submit-button' : ''
    }
  },
  mounted() {
  },
  methods: {
    //提交结果
    submitTest() {
      if (this.sortList.length < this.subjectList.length) return
      const tempList = this.sortList.map((item, index) => {
        return {
          SelSubject: item,
          Ranking: index + 1
        }
      })
      this.$ls.set('test-subjectSort', tempList)
      
      let temp = getStore('LoginData')
      if (!temp) {
        this.$message.info('请先登录！')
        return
      }

      API.Career.SelSubjectTest({
        UserId: JSON.parse(temp).UserId,
        SubjectRanking: tempList,
        AnswerList: this.$ls.get("test-newExamChooseAnswer"),
        ProvinceId: this.$ls.get("test-newExamChoose"),
      })
        .then((res) => {
          this.$router.push({
            path: '/test-result',
            query: {
              testType: '4'
            }
          })
        })

    },
    sortSubject(item) {
      if (!this.sortList.includes(item)) {
        this.sortList.push(item)
      }
    },
    //重新排序
    resetSort() {
      this.sortList = []
    },
    //选择科目的排序
    sortIndex(item) {
      const tempIndex = this.sortList.indexOf(item)
      return tempIndex === -1 ? '' : tempIndex + 1
    },
    //已选科目的状态
    selectedItem(item) {
      return this.sortList.includes(item) ? 'activate-item' : ''
    }
  }
}
</script>

<style scoped lang="less">

.main-box {
  width: 100%;
  background: linear-gradient(to bottom, rgba(var(--themecolor),0.75), rgba(var(--themecolor),1));
}

.sort-score {
  position: relative;
  max-width: 750px;
  margin: 0 auto;
  border: 1px solid #eeefff;

  .main {
    box-sizing: border-box;

    width: 100%;
    padding: 40px;
    position: absolute;
    top: 120px;
    left: 0;

    .tips {
      padding: 20px;
      font-weight: 700;
      text-align: center;
    }

    .item {
      padding: 15px 20px;
      margin-bottom: 10px;
      background-color: #ffffff;
      border-radius: 25px;
      text-align: center;
      position: relative;
      cursor: pointer;

      .order {
        position: absolute;
        left: 40px;
        top: 50%;
        transform: translateY(-50%);
      }

      .name {}
    }

    .activate-item {
      background: linear-gradient(to right, rgba(var(--themecolor),0.55), rgba(var(--themecolor),0.75), rgba(var(--themecolor),0.55));
      color: #ffffff;
    }

    .reset-button {
      padding: 20px;
      text-align: center;
      color: rgb(var(--themecolor));

      .button {
        cursor: pointer;
      }
    }

    .submit-button {
      width: 150px;
      padding: 10px 20px;
      margin: auto;
      text-align: center;
      border-radius: 20px;
      background-color: #A8A8BB;
      cursor: not-allowed;
      color: #ffffff;
    }

    .activate-submit-button {
      background-color: rgba(var(--themecolor),0.75);
      cursor: pointer;
    }
    .activate-submit-button:hover{
      background-color: rgba(var(--themecolor),1);
    }
  }
}

@media screen and (min-width: 1024px) {
  .sort-score {
    .main {
      .box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .item {
          width: 45%;
        }
      }
    }
  }
}</style>
